<template>
  <div class="home">
	  <Header></Header>
          <div class="feedback-main-box">
			<div class="product01-box">
				<div class="top-txt-select">
					<div class="left-txt lantinghei">热点问题</div>
					<div class="select-box">
						<div class="left-img">
							<img src="../assets/img/select-btn.png" alt="">
						</div>
						<div class="right-input">
							<input  v-model="searchText" @keyup.enter="selectGoAllMsg" type="text Gotham-Book lantingheijian" placeholder="搜索" class="input">
						</div>
					</div>
				</div>
				<div class="product01-content" v-if="allMsg.length>0">
					<div class="content-rel">
						<div class="content-box">
							<div class="top-content">
								<p class="p1 Gotham-Book lantingheijian">序号</p>
								<p class="p2 Gotham-Book lantingheijian">关键词</p>
							</div>
							<div class="content-bottom">
								<div class="content-slide" v-for="(item,index) in allMsg" :key="index">
                                    <div class="rel" v-if="index%2==0" @click="goDetails(item.id)">
                                        <p class="p1 Gotham-Book lantingheijian">{{index+1}}</p>
                                        <p class="p2 Gotham-Book lantingheijian">{{item.name}}</p>
                                        <p class="img-box">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='热'" src="../assets/img/hot.png" alt="">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='新'" src="../assets/img/new.png" alt="">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='沸'" src="../assets/img/fei.png" alt="">
                                        </p>
                                    </div>
								</div>
							</div>
					   </div>
					   <div class="content-box">
						   <div class="top-content">
							   <p class="p1 Gotham-Book lantingheijian">序号</p>
							   <p class="p2 Gotham-Book lantingheijian">关键词</p>
						   </div>
						   <div class="content-bottom">
							 <div class="content-slide" v-for="(item,index) in allMsg" :key="index">
                                    <div class="rel" v-if="index%2==1" @click="goDetails(item.id)">
                                        <p class="p1 Gotham-Book lantingheijian">{{index+1}}</p>
                                        <p class="p2 Gotham-Book lantingheijian">{{item.name}}</p>
                                        <p class="img-box">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='热'" src="../assets/img/hot.png" alt="">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='新'" src="../assets/img/new.png" alt="">
                                            <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='沸'" src="../assets/img/fei.png" alt="">
                                        </p>
                                    </div>
								</div>
						   </div>
					  </div>
					</div>
				   <div class="bottom-more-btn" @click="goAllMsg">
					   <span class="txt Gotham-Book lantingheijian">查看全部</span>
					   <span class="jiantou Gotham-Book lantingheijian">></span>
				   </div>
				</div>
				<div class="product01-content" v-else-if="allMsg.length<1&&!allMsgStatus">
                	 <img class="no-msg" src="../assets/img/loading.png" alt="">
				</div>
				<div class="product01-content" v-else>
                	 <img class="no-msg" src="../assets/img/zanwushuju.png" alt="">
				</div>
			</div>
			<div class="product02-box">
				<div class="top-tit lantinghei">问题意见</div>
				<div class="product02-content">
					<div class="left-box">
						<a target="_blank" href="https://docs.qq.com/form/page/DWFplb1duSXJoV0Vm?_w_tencentdocx_form=1#/fill" class="txt-left lantinghei">我要反馈问题</a>
						<img src="../assets/img/feedback-product02-img1.png" class="img" alt="">
					</div>
					<div class="left-box">
						<a href="https://docs.qq.com/form/page/DWHVlc1lpWVdpcUFs#/fill" target="_blank" class="txt-left lantinghei">我想提出意见</a>
						<img src="../assets/img/feedback-product02-img2.png" class="img" alt="">
					</div>
				</div>
			</div>
		    <div class="product03-box">
				<div class="top-tit lantinghei">联系我们</div>
				<div class="product03-content">
					<div class="slide">
						<p class="top-p lantinghei">社群</p>
						<p class="two-p Gotham-Book lantingheijian">咨询ZUI前端人员，<br> 获取最新信息反馈。</p>
						<div class="bottom-img">
                            <div class="img-box">
								<img src="../assets/img/feedback-product03-img1.png" alt="">
							</div>
							<p class="txt Gotham-Book lantingheijian">612808193</p>
						</div>
					</div>
					<div class="slide">
						<p class="top-p lantinghei">在线客服</p>
						<p class="two-p Gotham-Book lantingheijian">在线为您解答，方便高效。</p>
						<a href="https://lecs.lenovo.com.cn/" class="see-btn see-btn-line Gotham-Book lantingheijian">
							点此咨询
						</a>
					</div>
					<div class="slide">
						<p class="top-p lantinghei">电话客服</p>
						<p class="two-p Gotham-Book lantingheijian">电话咨询，预约客服。</p>
						<a href="tel:400-990-8888" class="see-btn Gotham-Book lantingheijian">
							400-990-8888
						</a>
					</div>
					<div class="slide">
						<p class="top-p lantinghei">邮件联系</p>
						<p class="two-p Gotham-Book lantingheijian">邮件留言</p>
						<a href="mailto:Lmservice@lenovo.com" class="see-btn Gotham-Book lantingheijian">
							Lmservice@lenovo.com
						</a>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios'
import Header from "../components/Header"
import Footer from "../components/Footer"
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
        //所有新闻
		allMsg:[],
		allMsgStatus:false,
      //首页banner
      bannerdata:[
        {coverPicture:''}
      ],
      //最新消息列表
      newMsg:[],
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
	  rightMoreArr:[],
	  searchText:'',
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // this.cacheKey = query.cacheKey;
    // this.getContent({"id": query.id})
    var indexBanner={
      "blockId": interfaces.blockIdArr.opinionarticle,
      "pageNum":1,
      "pageSize":16,
      "searchText":"",
      "filterConditionList":[]
    }
   this.searchFn(indexBanner)
//    this.getContent({"id": 3464})
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
      //获取右侧内容
        searchMoreFn(data){
            var $this = this;
            var config = {
                method: "post",
                url: `${interfaces.baseUrl}/portal/open/block/search`,
                headers:interfaces.postHeaders(),
                data,
                };
        
            axios(config)
                .then(function (response) {
                       $this.rightMoreArr=response.data.result;
                })
                .catch(function (error) {
                    return error
                });
        },
        //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
				$this.allMsg=response.data.result.records;
				$this.allMsgStatus=false
                //console.log($this.allMsg)
            })
            .catch(function (error) {
                return error
            });
      },
        //获取内容详情
        getContent(data){
          var $this = this;
          //console.log(this)
          var config = {
              method: "post",
              url: `${interfaces.baseUrl}/portal/open/block/getContent`,
              headers:interfaces.postHeaders(),
              data,
            };
            axios(config)
              .then(function (response) {
                  ////console.log(response.data);
                  return response
              })
              .catch(function (error) {
                  return error
              });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            return Y+M+D;
        },
        goDetails(id){
          this.$router.push({
            name: 'newsmsg',
            query:{
              id:id
            }
          })
		},
		goAllMsg(){
			this.$router.push({
				name: 'allMsg',
			})
		},
		selectGoAllMsg(){
			this.$router.push({
				name: 'allMsg',
				query:{
					searchText:this.searchText
				}
			})
		}
  },
};
</script>
<style lang="less" scoped> 
.footer-box{
	background: #ffffff !important;
}
.feedback-main-box{
	width: 100%;
	min-height: calc(100vh - 3rem);
	overflow: hidden;
	background: #f5f5f6;
	position: relative;
}
.feedback-main-box img{
	display: block;
	width: 100%;
}
.feedback-main-box .product01-box{
	width: 1200px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}
.feedback-main-box .product01-box .top-txt-select{
	margin-top: 2.75rem;
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #c6c6cc;
}
.feedback-main-box .product01-box .top-txt-select .left-txt{
	color:#0d0c22;
	font-size: 36px;
	float: left;
}
.feedback-main-box .product01-box .top-txt-select .select-box{
	float: right;
	width:438px;
	height:35px;
	line-height:35px;
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #dfe1e5;
	display: flex;
	box-sizing: border-box;
	align-items: center;
	margin-top: 7.5px;
}
.feedback-main-box .product01-box .top-txt-select .select-box .left-img{
	width:14px;
	margin-left:10px;
}
.feedback-main-box .product01-box .top-txt-select .select-box .right-input{
	background: none;
	margin-left: 7.5px;
	color: #000000;
	font-size: 12px;
}
.feedback-main-box .product01-box .top-txt-select .select-box input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.feedback-main-box .product01-box .top-txt-select .select-box input::-moz-placeholder { 
    color: #dfe1e5;
}
.feedback-main-box .product01-box .top-txt-select .select-box input:-ms-input-placeholder {
    color: #dfe1e5;
}
.feedback-main-box .product01-box .top-txt-select .select-box input:-moz-placeholder {
    color: #dfe1e5;
}
.feedback-main-box .product01-box .product01-content{
	margin-top:20px;
	background: #ffffff;
	width: 100%;
	padding:10px 18px 25px 18px;
	border-radius: 10px;
	box-sizing: border-box;
	overflow: hidden;
}
.feedback-main-box .product01-box .product01-content .content-box{
	width: 580px;
	float: left;
	border-right:1px solid #f2f2f5;
	box-sizing: border-box;
	padding-bottom:20px;
}
.feedback-main-box .product01-box .product01-content .content-rel{
	width: 100%;
	overflow: hidden;
}
.feedback-main-box .product01-box .product01-content .content-box:nth-of-type(2){
	border: none;
}
.feedback-main-box .product01-box .product01-content .content-box .top-content{
	width: 555px;
	margin: 0 auto;
	padding:8.5px 0;
	border-bottom:1px solid #f2f2f5;
	overflow: hidden;
}
.feedback-main-box .product01-box .product01-content .content-box .top-content .p1{
	width:51px;
	float: left;
	text-align: center;
	font-size:12px;
	color:#999999;
}
.feedback-main-box .product01-box .product01-content .content-box .top-content .p2{
	width:49.5px;
	float: left;
	text-align: left;
	font-size:12px;
	color:#999999;
}
.feedback-main-box .product01-box .product01-content .content-bottom{
	width: 100%;
	overflow: hidden;
}
.feedback-main-box .product01-box .product01-content .content-bottom .content-slide{
	overflow: hidden;
	cursor: pointer;
}
.feedback-main-box .product01-box .product01-content .content-bottom .content-slide .rel{
    display: block;
	overflow: hidden;
	margin: 0 auto;
	width: 555px;
	height:30px;
	line-height:30px;
	border-bottom:1px solid #f2f2f5;
}
.feedback-main-box .product01-box .product01-content .content-bottom .content-slide .p1{
	width:51px;
	float: left;
	text-align: center;
	font-size:12px;
	color:#f46d5f;
}
.feedback-main-box .product01-box .product01-content .content-bottom .content-slide .p2{
	width:382px;
	float: left;
	text-align: left;
	font-size:12px;
	color:#0078b6;
}
.feedback-main-box .product01-box .product01-content .content-bottom .content-slide .img-box{
	float: left;
	width:13px;
	margin-top: 8px;
}
.feedback-main-box .product01-box .product01-content .bottom-more-btn{
	width: 100%;
	text-align: center;
	margin-top:15px;
	font-size:15px;
	color:#0d0c22;
	cursor: pointer;
}
.feedback-main-box .product01-box .product01-content .bottom-more-btn .jiantou{
	color: #c6c6cc;
}

.feedback-main-box .product02-box{
	width:1200px;
	margin: 0 auto;
	margin-top:45px;
}
.feedback-main-box .product02-box .top-tit{
	padding-bottom:5px;
	border-bottom: 1px solid #c6c6cc;
	color: #0d0c22;
    font-size: 36px;
}
.feedback-main-box .product02-box .product02-content{
	width: 100%;
	overflow: hidden;
}
.feedback-main-box .product02-box .product02-content .left-box{
	float: left;
	width:590px;
	position: relative;
	margin-top:20px;
	background: #ffffff;
	border-radius:5px;
	cursor: pointer;
	height: 210px;
}
.feedback-main-box .product02-box .product02-content .left-box:nth-of-type(2){
	margin-left:11px;
}
.feedback-main-box .product02-box .product02-content .left-box .txt-left{
	position: absolute;
	top:95px;
	left:50px;
	color:#0d0c22;
	font-size: 24px;
	text-decoration: underline;
}
.feedback-main-box .product02-box .product02-content .left-box .img{
	position: absolute;
	top:34.5px;
	left:270px;
	width: 258px;
}
.feedback-main-box .product03-box{
	width: 1200px;
	margin: 0 auto;
	margin-top:45px;
	overflow: hidden;
	padding-bottom: 50px;
}
.feedback-main-box .product03-box .top-tit{
	padding-bottom:5px;
	border-bottom: 1px solid #c6c6cc;
	color: #0d0c22;
    font-size:36px;
}
.feedback-main-box .product03-box .product03-content{
	width: 100%;
	margin-top:20px;
}
.feedback-main-box .product03-box .product03-content .slide{
	float: left;
	width:292.5px;
	height:210px;
	margin-left:10px;
	background: #ffffff;
	border-radius:5px;
	overflow: hidden;
}
.feedback-main-box .product03-box .product03-content .slide:nth-of-type(1){
	margin-left: 0;
}
.feedback-main-box .product03-box .product03-content .slide .top-p{
	font-size:24px;
	color: #5a596c;
	width: 100%;
	text-align: center;
	margin-top:40px;
}
.feedback-main-box .product03-box .product03-content .slide .two-p{
	width: 100%;
	text-align: center;
	color: #5a596c;
	font-size:15px;
	height:60px;
	line-height:18px;
	margin-top: 7.5px;
}
.feedback-main-box .product03-box .product03-content .slide .bottom-img{
	width:180px;
	margin: 0 auto;
	margin-top: -6px;
	width:154px;
}
.feedback-main-box .product03-box .product03-content .slide .bottom-img .img-box{
	width: 44px;
	height:42px;
	overflow: hidden;
	float: left;
	position: relative;
}
.feedback-main-box .product03-box .product03-content .slide .bottom-img .txt{
   float: left;
    color: #5a596c;
	font-size: 15px;
	line-height: 42px;
	margin-left: 11px;
    // line-height: 18px;
    // margin-top: 7.5px;
}
.feedback-main-box .product03-box .product03-content .slide .bottom-img .img-box img{
	width:173.5px;
	height:42px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%,0);
}
// .feedback-main-box .product03-box .product03-content .slide img{
// 	display: block;
// 	margin: 0 auto;
// 	margin-top: 10px;
// 	width:173.5px;
// }
.feedback-main-box .product03-box .product03-content .slide .see-btn{
	text-align: center;
	width: 100%;
	line-height: 30px;
	display: block;
	color: #0078b6;
	font-size: 15px;
	text-decoration: underline;
	cursor: pointer;
}
.feedback-main-box .product03-box .product03-content .slide .see-btn-line{
	width:175px;
	height:30px;
	text-align: center;
	margin: 0 auto;
	border: 1px solid #0078b6;
	text-decoration:none;
	line-height:30px;
	border-radius: 30px;
	cursor: pointer;
}
.no-msg{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img{
  width: 100%;
}
</style>